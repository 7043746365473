import React from "react"
import Layout from "../components/Layout"
import { Link, graphql } from "gatsby"
import SEO from "../components/SEO"
import { formatTimestamp } from '../utils/dates'

import RecipeGrid from "../components/RecipeGrid";
import RecipeGridHeaderBlock from "../components/RecipeGridHeaderBlock";

export default function Blog({ data, location }) {
  return (
    <Layout>
      <SEO
        title="Recipes"
        description="All the recipes from Andrew Johnson"
        keywords={[`recipes`, `baking`, `Andrew Johnson`]}
        pathname={location.pathname}
      />
      <main className="space-y-6 mx-auto container px-4 pb-8">
        <RecipeGrid recipes={data.allMarkdownRemark.nodes}>
          <RecipeGridHeaderBlock>
            <h2 className="font-bold sm:text-right">Recipes</h2>
            <h2 aria-hidden="true" className="font-bold self-end hidden sm:block transform rotate-90 select-none">
              ⤴
            </h2>
          </RecipeGridHeaderBlock>
        </RecipeGrid>
        {/*data.allMarkdownRemark.edges.map(({ node }) => {
          return (
            <article key={node.fields.slug}>
              <header>
                <Link
                  to={`/recipes${node.fields.slug}`}
                  className="flex items-center space-x-2"
                >
                  <small className="p-2 bg-yellow-300 font-mono pride-background">
                    {formatTimestamp(node.frontmatter.date)}
                  </small>
                  <h3 className="font-bold text-lg">
                    {node.frontmatter.title}
                  </h3>
                </Link>
              </header>
            </article>
          )
        })*/}
      </main>
    </Layout>
  )
}

export const query = graphql`
  query {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fileAbsolutePath: { regex: "/content/recipes/" } }
    ) {
      nodes {
        frontmatter {
          title
          date
          description
          featured {
            childImageSharp {
              gatsbyImageData(width: 1024, aspectRatio: 1)
            }
          }
        }
        fields {
          slug
        }
      }
      edges {
        node {
          frontmatter {
            title
            date
            description
            featured {
              childImageSharp {
                gatsbyImageData(width: 1024, aspectRatio: 1)
              }
            }
          }
          fields {
            slug
          }
        }
      }
    }
  }
`
